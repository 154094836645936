<template>
  <div>
    <el-menu
      router
      active-text-color="#1492FF"
      background-color="#fff"
      text-color="#303133"
      :default-active="$route.meta.name"
      :collapse="isCollapse"
      :collapse-transition="false"
    >
      <template v-for="(item, i) in $store.state.menu_tree" :key="i">
        <template v-if="item._child">
          <el-sub-menu :index="item.url" :key="i" v-if="item.type==1">
            <template #title>
              <img :src="$http+item.icon" class="icon" v-if="item.icon" alt="">
              <span>{{ item.title }}</span>
            </template>
            <template v-for="c in item._child" :key="c.url">
              <el-menu-item :index="c.url" 
                ><span slot="title">{{ c.title }}</span></el-menu-item>
            </template>
          </el-sub-menu>
          <el-menu-item v-else :index="item.url"
            ><img :src="$http+item.icon"  class="icon" v-if="item.icon" alt=""><span slot="title">{{ item.title }}</span></el-menu-item>
        </template>
        <template v-else>
          <el-menu-item :index="item.url">
            <img :src="$http+item.icon"  class="icon" v-if="item.icon" alt="">
            <span slot="title">{{ item.title }}</span>
          </el-menu-item>
        </template>
      </template>
    </el-menu>
  </div>
</template>

<script>
export default {
  props: {
    isCollapse: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    this.get_urls();
  },
  
   methods: {
       get_urls(){
           this.$httpGet("/backend/AdminMenu/index", {})
             .then((res) => {
               if (res.status == 200) {
                 this.$store.commit("SET_MENU", res.data.menu_list || []);
                 this.$store.commit("SET_MENU_TREE", res.data.menu_tree || []);
               } else {
                 this.$message.error(res.message);
               }
             })
             .catch((err) => {
               console.log(err);
             });
       }
   },
  data() {
    return {
      menuList: [
        //采购管理
        {
          path: "/purchasePlan",
          name: "采购管理",
          icon: "el-icon-menu",
          children: [
            {
              path: "/purchasePlan",
              name: "采购计划单",
            },
            {
              path: "/purchaseAccept",
              name: "采购验收单",
            },
            {
              path: "/purchasePut",
              name: "采购入库单",
            },
            {
              path: "/purchaseBack",
              name: "采购退货单",
            },
            {
              path: "/purchaseSlect",
              name: "采购业务查询",
            },
          ],
        },
        //销售管理
        {
          path: "/salesPlan",
          name: "销售管理",
          icon: "el-icon-menu",
          children: [
            {
              path: "/salesPlan",
              name: "销售计划单",
            },
            {
              path: "/salesOut",
              name: "销售出库单",
            },
            {
              path: "/salesBack",
              name: "销售退回计划",
            },
            {
              path: "/salesPut",
              name: "产品账页",
            },
          ],
        },
        //首营管理
        {
          path: "/camp",
          name: "首营管理",
          icon: "el-icon-menu",
        },
        //仓库养护
        {
          path: "/entrepot",
          name: "仓库养护管理",
          icon: "el-icon-menu",
        },
        //库管业务
        {
          path: "/blitem",
          name: "库管业务",
          icon: "el-icon-menu",
          children: [
            {
              path: "/blitem",
              name: "盘点单",
            },
            {
              path: "/breakage",
              name: "报损报益管理",
            },
            {
              path: "/discontinued",
              name: "停售产品单",
            },
            {
              path: "/inventory",
              name: "库存查询",
            },
          ],
        },
        //货位仓库
        {
          path: "/goods",
          name: "货位仓库",
          icon: "el-icon-menu",
          children: [
            {
              path: "/goods",
              name: "货位管理",
            },
            {
              path: "/Batchnum",
              name: "货位产品批号效期修改",
            },
          ],
        },
        //职位管理
        {
          path: "/job",
          name: "职位管理",
          icon: "el-icon-menu",
        },
        //部门管理
        {
          path: "/department",
          name: "部门管理",
          icon: "el-icon-menu",
        },
        //人员管理
        {
          path: "/personnel",
          name: "人员管理",
          icon: "el-icon-menu",
        },
        //辅助财务
        {
          path: "/financial",
          name: "辅助财务",
          icon: "el-icon-menu",
        },
        //客户供应商管理
        {
          path: "/customer",
          name: "客户供应商管理",
          icon: "el-icon-menu",
        },
        //产品分类
        {
          path: "/productList",
          name: "产品分类",
          icon: "el-icon-menu",
          children: [
            {
              path: "/productList",
              name: "产品列表",
            },
            {
              path: "/packaging",
              name: "包装单位",
            },
            {
              path: "/category",
              name: "类别管理",
            },
            {
              path: "/spec",
              name: "规格管理",
            },
            {
              path: "/memory",
              name: "存储条件",
            },
            {
              path: "/classify",
              name: "分类管理",
            },
          ],
        },
        
        //系统设置
        {
          path: "/system",
          name: "系统设置",
          icon: "el-icon-menu",
        },
        //系统消息
        {
          path: "/msg",
          name: "系统消息",
          icon: "el-icon-menu",
        },
        //菜单设置
        {
          path: "/menu",
          name: "菜单设置",
          icon: "el-icon-menu",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
    .icon{width: 18px; height:18px; margin-right: 10px;}
</style>
